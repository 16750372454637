// extracted by mini-css-extract-plugin
export var button = "PlasmicLogin-module--button--Crd-0";
export var column = "PlasmicLogin-module--column--YYaRG";
export var columns = "PlasmicLogin-module--columns--n+GWW";
export var container4 = "PlasmicLogin-module--container4--zLUFJ";
export var h2 = "PlasmicLogin-module--h2--L+IVS";
export var label__x7J2 = "PlasmicLogin-module--label__x7J2--nPEJX";
export var label__xz6Cj = "PlasmicLogin-module--label__xz6Cj--yly3E";
export var link = "PlasmicLogin-module--link--kJMii";
export var mainSection = "PlasmicLogin-module--mainSection--Z1FUw";
export var mainSection2 = "PlasmicLogin-module--mainSection2--N0UFm";
export var root = "PlasmicLogin-module--root--caEyo";
export var svg___6ADjv = "PlasmicLogin-module--svg___6ADjv--2qtot";
export var svg__eH9FS = "PlasmicLogin-module--svg__eH9FS--j+Mo0";
export var svg__etNZt = "PlasmicLogin-module--svg__etNZt--KpTm3";
export var svg__hDzHm = "PlasmicLogin-module--svg__hDzHm--LJ9jE";
export var svg__l7Okt = "PlasmicLogin-module--svg__l7Okt--ZwWvY";
export var svg__mwaEx = "PlasmicLogin-module--svg__mwaEx--BF1kj";
export var svg__oszGe = "PlasmicLogin-module--svg__oszGe--Y-t8w";
export var svg__phHo = "PlasmicLogin-module--svg__phHo--QblAK";
export var text = "PlasmicLogin-module--text--U4G8J";
export var textInput__cFHn = "PlasmicLogin-module--textInput__cFHn---ewpk";
export var textInput__rt1Uv = "PlasmicLogin-module--textInput__rt1Uv--rq8xK";
export var tilt___4Es7H = "PlasmicLogin-module--tilt___4Es7H--hOt2O";
export var tilt__bnfR = "PlasmicLogin-module--tilt__bnfR--+-vK2";
export var tilt__l4WkN = "PlasmicLogin-module--tilt__l4WkN--opysq";